/** @jsxImportSource react */
import { globalStore, logout } from '$/store'
import { Popover, Transition } from '@headlessui/react'
import { useStore } from '@nanostores/react'
import clsx from 'clsx'
import { Fragment, useMemo } from 'react'
import { ReactComponent as AccountIcon } from '$/icons/redesign/account.svg'
import { useEffect, useState } from 'react'

type MenuView = 'user' | 'subscriber' | 'guest'

type AccountMenuItem = {
  name: string
  description: string
  href: string
  view: MenuView
}

const menu: AccountMenuItem[] = [
  { name: 'Recommendations', description: 'View your RD recommended products', href: '/recommendations', view: 'user' },
  { name: 'My next box', description: 'View & manage your HUM Box', href: '/my_account', view: 'subscriber' },
  {
    name: 'Routine',
    description: 'Customize your HUM routine',
    href: '/my_account/routine',
    view: 'subscriber',
  },
  {
    name: 'Ask your nutritionist',
    description: 'View your nutrition report',
    href: '/my_account/nutritionist',
    view: 'subscriber',
  },
  {
    name: 'My HUM rewards',
    description: 'Earn & redeem points for $$$ off',
    href: '/my_account/rewards',
    view: 'subscriber',
  },
  {
    name: 'Refer + earn',
    description: 'Refer friends',
    href: '/my_account/referrals',
    view: 'subscriber',
  },
  {
    name: 'Orders',
    description: 'View & track your online orders',
    href: '/my_account/order-history',
    view: 'subscriber',
  },
  {
    name: 'Account',
    description: 'View or edit your shipping & billing info',
    href: '/my_account/profile',
    view: 'subscriber',
  },
]

export default function HeaderAccountButton() {
  const { user, subscription } = useStore(globalStore)
  const [isOpen, setIsOpen] = useState(false)
  const isLoggedIn = !!user?.id
  const userType = useMemo(() => {
    if (subscription?.id) return 'subscriber'
    else if (user?.id) return 'user'
    else return 'guest'
  }, [user, subscription])

  const menuItems = menu.filter((item) => item.view === userType)

  const login = () => window.dispatchEvent(new CustomEvent('ui:login'))

  // for klaviyo $10 off coupon which has z-index of 90000
  useEffect(() => {
    if (isOpen) {
      const element = document.querySelector('.site-head') as HTMLElement | null

      if (element) {
        element.style.zIndex = '90001'
      }

      return () => {
        if (element) {
          element.style.zIndex = ''
        }
      }
    }
  }, [isOpen])

  return (
    <>
      <Popover className="tablet:u-relative" data-grid="account">
        {({ open }) => {
          useEffect(() => {
            setIsOpen(open)
          }, [open])

          return (
            <>
              <Popover.Button
                className={clsx(
                  open ? 'u-text-gray-900' : 'u-text-gray-500',
                  'u-hover:text-gray-900 u-focus:outline-none u-focus:ring-2 u-focus:ring-indigo-500 u-focus:ring-offset-2 u-group u-grid u-h-[32px] u-w-[32px] u-place-content-center u-items-center u-rounded-md u-text-base u-font-medium',
                )}
                aria-label="account menu"
              >
                <AccountIcon className="nav-icon" />
              </Popover.Button>

              <Transition
                as={Fragment}
                enter="u-transition u-ease-out u-duration-200"
                enterFrom="u-opacity-0 u-translate-y-1"
                enterTo="u-opacity-100 u-translate-y-0"
                leave="u-transition u-ease-in u-duration-150"
                leaveFrom="u-opacity-100 u-translate-y-0"
                leaveTo="u-opacity-0 u-translate-y-1"
              >
                <Popover.Panel className="u-sm:px-0 u-absolute u-left-1/2 u-z-10 u-mt-3 u-w-screen u-max-w-xs u--translate-x-1/2 u-transform u-px-2 u-text-dark tablet:u-left-auto tablet:u-right-0 tablet:u-translate-x-0 tablet:u-px-0">
                  <div className="u-overflow-hidden u-rounded-lg u-shadow-lg u-ring-1 u-ring-black u-ring-opacity-5">
                    <div className="u-grid u-bg-white">
                      {menuItems.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="u-block u-border-b u-p-3 u-transition u-duration-150 u-ease-in-out hover:u-bg-light"
                        >
                          <p className="p2 u-font-semibold">{item.name}</p>
                          <p className="p1">{item.description}</p>
                        </a>
                      ))}
                      {isLoggedIn ? (
                        <button onClick={logout} className="p2 u-block u-p-4 u-text-left u-text-gray">
                          Log out
                        </button>
                      ) : (
                        <button onClick={login} className="p2 u-block u-p-4 u-text-left">
                          Log in
                        </button>
                      )}
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )
        }}
      </Popover>
    </>
  )
}
